export enum PermissionsEnum {
  PERM_ADMINS = 'PERM_ADMINS',
  PERM_FILES = 'PERM_FILES',
  PERM_USERS_READ = 'PERM_USERS_READ',
  PERM_USERS_UPDATE = 'PERM_USERS_UPDATE',
  PERM_USERS_BLOCK = 'PERM_USERS_BLOCK',
  PERM_USERS_DELETE = 'PERM_USERS_DELETE',
  PERM_USERS_ACCOUNT = 'PERM_USERS_ACCOUNT',
  PERM_USERS_DEPOSIT = 'PERM_USERS_DEPOSIT',
  PERM_RUBLE_TX = 'PERM_RUBLE_TX',
  PERM_INVOICES = 'PERM_INVOICES',
  PERM_PYT_ACCOUNTS = 'PERM_PYT_ACCOUNTS',
  PERM_PYT_INVOICES = 'PERM_PYT_INVOICES',
  PERM_ARBITRAGE = 'PERM_ARBITRAGE',
  PERM_AUDIT = 'PERM_AUDIT',
  PERM_SKILLS = 'PERM_SKILLS',
  PERM_EMAIL = 'PERM_EMAIL',
  PERM_SUPPORT = 'PERM_SUPPORT',
  PERM_FEEDBACKS = 'PERM_FEEDBACKS',
  PERM_PROPERTIES = 'PERM_PROPERTIES',
  PERM_DOCUMENT_TEMPLATE = 'PERM_DOCUMENT_TEMPLATE',
  PERM_SERVICE_DOCUMENTS = 'PERM_SERVICE_DOCUMENTS',
  PERM_EXTERNAL_API = 'PERM_EXTERNAL_API',
  PERM_CO_PRODUCTS= 'PERM_CO_PRODUCTS',
  PERM_CO_PRODUCT_DEALS= 'PERM_CO_PRODUCT_DEALS',
}
