import { PermissionsEnum } from '@/permissions.enum'
import { useStore } from '@/store'
import { AdminPermission } from '@/models/admin-backend'

export default () => {
  const hasPermission = (perm: PermissionsEnum): boolean => {
    const store = useStore()
    return !!store.getters.ADMIN_INFO?.role?.permissions.find(
      (permission: AdminPermission) => permission.value === perm.valueOf(),
    )
  }

  return {
    hasPermission,
  }
}
