import { key, store } from '@/store'
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { iconsSet as icons } from '@/assets/icons/icons.js'
import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import PrimeVue from 'primevue/config'
import WebSocketsPlugin from "@/views/notifications/WebSocketsPlugin";

createApp(App)
  .provide('telegramBotUsername', window['telegramBotUsername'])
  .use(store, key)
  .use(router)
  .use(CoreuiVue)
  .use(PrimeVue)
  .use(WebSocketsPlugin)
  .provide('icons', icons)
  .provide('store', store)
  .provide('router', router)
  .provide('route', router.currentRoute)
  .component('CIcon', CIcon)
  .mount('#app')
