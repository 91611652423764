import { InjectionKey } from 'vue'
import { ActionContext, createStore, Store, useStore as baseUseStore } from 'vuex'
import restTemplate from '@/restTemplate'
import debug from '@/debug'
import { AdminDto, AdminNotificationDto, RoleDto } from '@/models/admin-backend'
import { useRouter } from 'vue-router'
// import { connectWebSocket, disconnectWebSocket } from "@/views/notifications/Websockets.vue";

const URLS = {
  ME: '/api/admins/me',
  ROLES: '/api/roles',
  UNREAD_EVENTS: '/api/chat/unreadCount',
  UNREAD_NOTIFICATIONS: '/api/adminNotification/unreadCount',
}

// define your typings for the index state
export interface State {
  adminInfo: AdminDto
  roles: Array<RoleDto>
  authenticated: boolean
  sidebarVisible: string | boolean
  sidebarUnfoldable: boolean
  unreadEvents: number
  notifications: Array<AdminNotificationDto>
  unreadNotifications: number
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

const updateUnreadNotifications = async (context: ActionContext<State, State>) => {
  const { data } = await restTemplate.get(URLS.UNREAD_NOTIFICATIONS).catch(() => {
    return { data: null }
  })
  context.commit('SET_UNREAD_NOTIFICATIONS', data);
}

export const store = createStore<State>({
  strict: debug.debug,
  state: {
    adminInfo: {} as AdminDto,
    roles: [],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    authenticated: !!window.isAuthenticated,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    unreadEvents: 0,
    notifications: [],
    unreadNotifications: 0,
  },
  getters: {
    IS_AUTHENTICATED: (state): boolean => {
      return state.authenticated
    },
    ADMIN_INFO: (state): AdminDto => {
      return state.adminInfo
    },
    ROLES: (state): Array<RoleDto> => {
      return state.roles
    },
    UNREAD_EVENTS: (state): number => {
      return state.unreadEvents
    },
    NOTIFICATIONS: (state): Array<AdminNotificationDto> => {
      return state.notifications
    },
    UNREAD_NOTIFICATIONS: (state): number => {
      return state.unreadNotifications
    },
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    SET_ADMIN: (state, model: AdminDto) => {
      state.adminInfo = model
      state.authenticated = !!model
    },
    SET_ROLES: (state, model: Array<RoleDto>) => {
      state.roles = model
    },
    SET_UNREAD_EVENTS: (state, payload) => {
      state.unreadEvents = payload
    },
    SET_NOTIFICATIONS: (state, model: Array<AdminNotificationDto>) => {
      state.notifications = model
    },
    SET_UNREAD_NOTIFICATIONS: (state, payload) => {
      state.unreadNotifications = payload
    },
  },
  actions: {
    UPDATE_ADMIN_INFO: async (context) => {
      const { data } = await restTemplate.get(URLS.ME).catch((response) => {
        if (response.status === 403) {
          const router = useRouter()
          router.push({ name: 'Page403' })
        }
        return { data: null }
      })
      context.commit('SET_ADMIN', data)
      await updateUnreadNotifications(context);
    },
    LOGOUT: async (context) => {
      await context.commit('SET_ADMIN', null)
    },
    UPDATE_ROLES: async (context) => {
      const { data } = await restTemplate.get(URLS.ROLES).catch(() => {
        return { data: null }
      })
      context.commit('SET_ROLES', data)
    },
    UPDATE_UNREAD_EVENTS: async (context) => {
      const { data } = await restTemplate.get(URLS.UNREAD_EVENTS).catch(() => {
        return { data: null }
      })
      context.commit('SET_UNREAD_EVENTS', data)
    },
    UPDATE_UNREAD_NOTIFICATIONS: async (context, increment) => {
      await updateUnreadNotifications(context);
    },
  },
  modules: {},
})

export function useStore() {
  return baseUseStore(key)
}
