import { Plugin } from "vue";
import SockJS from "sockjs-client";
import { Client, IMessage } from "@stomp/stompjs";
import { store } from "@/store";

const baseURL = process.env['VUE_APP_ROOT_API'];
const wsURL = '/ws/connect';
const wsDestination = '/user/queue/adminNotifications';


const createWebSockets = (): void => {
  const url: string = baseURL + wsURL;
  const socket: WebSocket = new SockJS(url);
  const stompClient: Client = new Client({
    webSocketFactory: () => socket,
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
  })

  stompClient.onConnect = (): void => {
    stompClient.subscribe(wsDestination, (message:IMessage): void => {
      updateUnreadNotifications();
    })
  }
  stompClient.activate()
}

const provideWebSockets = (): void => {
  store.watch(state => state.authenticated, () => {
    if (store.getters.IS_AUTHENTICATED) {
      createWebSockets();
    }
  })
}

const WebSocketsPlugin: Plugin = {
  install() {
    provideWebSockets()
  }
}

const updateUnreadNotifications = (): void => {
  store.dispatch('UPDATE_UNREAD_NOTIFICATIONS').then();
}

export default WebSocketsPlugin;
