<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-user" /> {{admin?.name}}
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-user" /> Роль: {{admin?.role?.name}}
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Настройки
      </CDropdownHeader>
      <CDropdownItem href="/settings"> <CIcon icon="cil-settings" /> Настройки </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="logout">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/no-photo.png'
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import http from '@/restTemplate'
import { useStore } from '@/store'

export default {
  name: 'AppHeaderDropdownAccnt',
  components: {
    CDropdown,
    CDropdownToggle,
    CAvatar,
    CDropdownMenu,
    CDropdownHeader,
    CDropdownItem,
    CDropdownDivider,
    CIcon,
  },
  setup() {
    const store = useStore()
    const logout = () => {
      http
        .post('/logout')
        .then(() =>
          store.dispatch('LOGOUT').then(() => window.location.reload()),
        )
    }
    return {
      avatar: avatar,
      logout,
      admin: store.state.adminInfo,
    }
  },
}
</script>
