import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { store } from '@/store'
import { AdminDto } from '@/models/admin-backend'
import { PermissionsEnum } from '@/permissions.enum'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/settings',
        name: 'Настройки',
        meta: {
          auth: true,
        },
        component: () => import('@/views/adminSettings/AdminSettings.vue'),
      },
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: 'users',
        meta: {
          label: 'Список пользователей',
          permissions: [PermissionsEnum.PERM_USERS_READ],
          auth: true,
        },
        name: 'Список пользователей',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            name: 'Пользователи',
            meta: {
              label: 'Пользователи',
              permissions: [PermissionsEnum.PERM_USERS_READ],
              auth: true,
            },
            path: '',
            component: () => import('@/views/users/Users.vue'),
            props: true,
          },
          {
            path: ':id',
            name: 'Пользователь',
            meta: {
              label: 'Пользователь',
              permissions: [PermissionsEnum.PERM_USERS_READ],
              auth: true,
            },
            component: () => import('@/views/users/User.vue'),
          },
          {
            path: ':id/account',
            meta: {
              permissions: [PermissionsEnum.PERM_USERS_ACCOUNT],
              auth: true,
            },
            component: () => import('@/views/users/Account.vue'),
          },
        ],
      },
      // {
      //   path: 'arbitrage',
      //   meta: {
      //     label: 'Арбитраж',
      //     permissions: [],
      //   },
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   children: [
      //     {
      //       meta: {
      //         permissions: [PermissionsEnum.PERM_ARBITRAGE],
      //         auth: true,
      //       },
      //       path: '',
      //       component: () => import('@/views/arbitrage/ArbitrageTable.vue'),
      //       props: true,
      //     },
      //     {
      //       path: ':id',
      //       meta: {
      //         label: 'Арбитраж',
      //         permissions: [PermissionsEnum.PERM_ARBITRAGE],
      //         auth: true,
      //       },
      //       name: 'Arbitrage',
      //       component: () => import('@/views/arbitrage/Arbitrage.vue'),
      //     },
      //   ],
      // },
      {
        path: 'profile',
        name: 'Profile',
        meta: {
          label: 'Профиль',
          auth: true,
        },
        component: () => import('@/views/admins/Admin.vue'),
      },
      {
        redirect: '/admins/list',
        path: 'admins',
        meta: {
          permissions: [PermissionsEnum.PERM_ADMINS],
          auth: true,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            name: 'Администраторы',
            meta: {
              label: 'Администраторы',
              permissions: [PermissionsEnum.PERM_ADMINS],
              auth: true,
            },
            path: '',
            component: () => import('@/views/admins/Admins.vue'),
          },
          {
            path: ':id',
            meta: {
              label: 'Администратор',
              permissions: [PermissionsEnum.PERM_ADMINS],
              auth: true,
            },
            name: 'Admin',
            component: () => import('@/views/admins/Admin.vue'),
          },
          {
            path: '/new',
            meta: {
              label: 'Добавление администратора',
              permissions: [PermissionsEnum.PERM_ADMINS],
              auth: true,
            },
            component: () => import('@/views/admins/Admin.vue'),
          },
          {
            path: '/roles',
            meta: {
              label: 'Роли',
              permissions: [PermissionsEnum.PERM_ADMINS],
              auth: true,
            },
            component: () => import('@/views/admins/Roles.vue'),
          },
          {
            path: '/roles/:id',
            meta: {
              label: 'Редактирование роли',
              permissions: [PermissionsEnum.PERM_ADMINS],
              auth: true,
            },
            component: () => import('@/views/admins/Role.vue'),
          },
          {
            path: '/roles/new',
            meta: {
              label: 'Добавление роли',
              permissions: [PermissionsEnum.PERM_ADMINS],
              auth: true,
            },
            component: () => import('@/views/admins/Role.vue'),
          },
        ],
      },
      {
        path: 'files',
        name: 'Библиотека файлов',
        meta: {
          label: 'Библиотека файлов',
          permissions: [PermissionsEnum.PERM_FILES],
          auth: true,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            meta: {
              permissions: [PermissionsEnum.PERM_FILES],
              auth: true,
            },
            name: 'Файлы',
            path: '',
            component: () => import('@/views/files/Files.vue'),
          },
          {
            path: ':id',
            meta: {
              label: 'Файл',
              permissions: [PermissionsEnum.PERM_FILES],
              auth: true,
            },
            name: 'File',
            component: () => import('@/views/files/File.vue'),
          },
          {
            path: '/new',
            meta: {
              label: 'Добавление Файла',
              permissions: [PermissionsEnum.PERM_FILES],
              auth: true,
            },
            component: () => import('@/views/files/File.vue'),
          },
        ],
      },
      {
        path: 'skills',
        meta: {
          label: 'Навыки',
          permissions: [PermissionsEnum.PERM_SKILLS],
          auth: true,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            meta: {
              permissions: [PermissionsEnum.PERM_SKILLS],
              auth: true,
            },
            path: '',
            component: () => import('@/views/skills/Skills.vue'),
          },
          {
            path: ':id',
            meta: {
              label: 'Навык',
              permissions: [PermissionsEnum.PERM_SKILLS],
              auth: true,
            },
            name: 'Skill',
            component: () => import('@/views/skills/Skill.vue'),
          },
          {
            path: '/new',
            meta: {
              label: 'Добавление Навыка',
              permissions: [PermissionsEnum.PERM_SKILLS],
              auth: true,
            },
            component: () => import('@/views/skills/Skill.vue'),
          },
        ],
      },
      {
        path: 'emails',
        meta: {
          label: 'Рассылка sendpulse',
          permissions: [PermissionsEnum.PERM_EMAIL],
          auth: true,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            meta: {
              permissions: [PermissionsEnum.PERM_EMAIL],
              auth: true,
            },
            path: '',
            component: () => import('@/views/emails/Emails.vue'),
          },
          {
            path: ':id',
            meta: {
              label: 'Рассылка sendpulse',
              permissions: [PermissionsEnum.PERM_EMAIL],
              auth: true,
            },
            name: 'Email',
            component: () => import('@/views/emails/Email.vue'),
          },
        ],
      },
      {
        path: 'finance',
        redirect: '/finance/legal-transactions',
        name: 'Финансы',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/finance/legal-transactions',
            name: 'Управление сделками',
            meta: {
              label: 'Управление сделками',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '',
                name: 'Сделки',
                meta: {
                  label: 'Сделки',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () =>
                  import('@/views/finance/LegalTransactions.vue'),
              },
              {
                path: ':id',
                name: 'Сделка',
                meta: {
                  label: 'Сделка',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () => import('@/views/finance/LegalTransaction.vue'),
              },
            ],
          },
          {
            path: '/finance/invoice',
            name: 'Инвойсы',
            meta: {
              label: 'Инвойсы',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: () => import('@/views/finance/Invoices.vue'),
          },
          {
            path: '/finance/mass-payments',
            name: 'Массовые выплаты',
            meta: {
              label: 'Массовые выплаты',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '',
                name: 'Массовые Выплаты',
                meta: {
                  label: 'Массовые Выплаты',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () =>
                  import('@/views/finance/mass-payments/MassPayments.vue'),
              },
              {
                path: ':id',
                name: 'Массовая выплата',
                meta: {
                  label: 'Массовая выплата',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () => import('@/views/finance/mass-payments/MassPayment.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/tochka',
        redirect: '/tochka/transfer',
        name: 'Точка банк',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/tochka/transfer',
            name: 'Проведение оплаты',
            meta: {
              label: 'Проведение оплаты',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: () => import('@/views/finance/BankTransfer.vue'),
          },
          {
            path: '/tochka/top-up',
            name: 'Пополнение баланса CO',
            meta: {
              label: 'Пополнение баланса CO',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: () => import('@/views/finance/tochka-bank/CreativeObjectsTopUp.vue'),
          },
          {
            path: '/tochka/deals',
            redirect: '',
            name: 'Список сделок',
            meta: {
              label: 'Список сделок',
              permissions: [PermissionsEnum.PERM_USERS_READ],
              auth: true,
            },
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '',
                name: 'Сделки (Точка)',
                meta: {
                  label: 'Сделки',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () => import('@/views/finance/Deals.vue'),
              },
              {
                path: ':id',
                name: 'Сделка (Точка)',
                meta: {
                  label: 'Сделка',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () => import('@/views/finance/Deal.vue'),
              },
            ],
          },
          {
            path: '/tochka/payments',
            redirect: 'payments',
            name: 'Платежи',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '',
                name: 'Список платежей',
                meta: {
                  label: 'Список платежей',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () => import('@/views/finance/Payments.vue'),
              },
              {
                path: ':id',
                name: 'Платеж',
                meta: {
                  label: 'Платеж',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () => import('@/views/finance/Payment.vue'),
              },
            ],
          },
          {
            path: '/tochka/unidentified-payments',
            redirect: 'unidentified-payments',
            name: 'Неидентиф. платежи',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '',
                name: 'Список неидентиф. платежей',
                meta: {
                  label: 'Список неидентиф. платежей',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () => import('@/views/finance/tochka-bank/UnidentifiedPayments.vue'),
              },
              {
                path: ':id',
                name: 'Платеж(неидентиф)',
                meta: {
                  label: 'Платеж(неидентиф)',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
                component: () => import('@/views/finance/Payment.vue'),
              },
            ],
          },
          {
            path: '/tochka/beneficiary-list',
            name: 'Бенефициары',
            meta: {
              label: 'Список бенефициаров',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: () =>
              import('@/views/finance/tochka-bank/BeneficiaryList.vue'),
          },
          {
            path: '/tochka/beneficiary-list/beneficiary',
            name: 'Бенефициар',
            meta: {
              label: 'Бенефициар',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: () =>
              import('@/views/finance/tochka-bank/Beneficiary.vue'),
          },
          {
            path: '/tochka/transactions',
            name: 'Транзакции Точка',
            meta: {
              label: 'Транзакции Точка',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: () =>
              import('@/views/finance/tochka-bank/VirtualTransactions.vue'),
          },
          {
            path: '/tochka/virtual-accounts',
            name: 'Виртуальные аккаунты',
            meta: {
              label: 'Виртуальные аккаунты',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: () =>
              import('@/views/finance/tochka-bank/VirtualAccounts.vue'),
          },
        ],
      },
      {
        path: 'audit',
        name: 'Действия администраторов',
        meta: {
          label: 'Действия администраторов',
          permissions: [PermissionsEnum.PERM_AUDIT],
          auth: true,
        },
        component: () => import('@/views/audit/Audit.vue'),
      },
      {
        path: 'audit/:id',
        name: 'Запись в истории аудита',
        meta: {
          label: 'Запись в истории аудита',
          permissions: [PermissionsEnum.PERM_AUDIT],
          auth: true,
        },
        component: () => import('@/views/audit/AuditRow.vue'),
      },
      {
        path: 'support-chat',
        name: 'Чат поддержки',
        meta: {
          label: 'Чат поддержки',
          permissions: [PermissionsEnum.PERM_SUPPORT],
          auth: true,
        },
        component: () => import('@/views/support-chat/SupportChat.vue'),
      },
      {
        path: 'journal',
        meta: {
          label: 'Журнал',
          permissions: [PermissionsEnum.PERM_USERS_READ],
          auth: true,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            meta: {
              permissions: [PermissionsEnum.PERM_USERS_READ],
              auth: true,
            },
            props: true,
            component: () => import('@/views/journal/Journal.vue'),
          },
          {
            path: ':id',
            name: 'UserJournal',
            meta: {
              label: 'Действия пользователя',
              permissions: [PermissionsEnum.PERM_USERS_READ],
              auth: true,
            },
            component: () => import('@/views/journal/UserJournal.vue'),
          },
        ],
      },
      {
        path: 'feedbacks',
        name: 'Обратная связь',
        meta: {
          label: 'Обратная связь',
          permissions: [PermissionsEnum.PERM_FEEDBACKS],
          auth: true,
        },
        component: () => import('@/views/feedbacks/Feedbacks.vue'),
      },
      {
        path: '/appSettings',
        name: 'Системные настройки',
        component: () => import('@/views/appSettings/AppSettings.vue'),
        meta: {
          label: 'Системные настройки',
          permissions: [PermissionsEnum.PERM_PROPERTIES],
          auth: true,
        },
      },
      {
        path: '/products',
        name: 'Продукты',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Список Продуктов',
            meta: {
              permissions: [PermissionsEnum.PERM_CO_PRODUCTS],
              auth: true,
            },
            props: true,
            component: () => import('@/views/creative-objects/Products.vue'),
          },
          {
            path: ':id',
            name: 'Продукт',
            meta: {
              permissions: [PermissionsEnum.PERM_CO_PRODUCTS],
              auth: true,
            },
            component: () => import('@/views/creative-objects/Product.vue'),
          },
        ]
      },
      {
        path: '/co-deals',
        name: 'Сделки Creative Objects',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Список cделок CO',
            meta: {
              permissions: [PermissionsEnum.PERM_CO_PRODUCT_DEALS],
              auth: true,
            },
            props: true,
            component: () => import('@/views/creative-objects/CoDeals.vue'),
          },
          {
            path: ':id',
            name: 'Сделка CO',
            meta: {
              permissions: [PermissionsEnum.PERM_CO_PRODUCT_DEALS],
              auth: true,
            },
            component: () => import('@/views/creative-objects/CoDeal.vue'),
          },
        ]
      },
      {
        path: '/pyt',
        redirect: '/pyt/invoices',
        name: 'Pay Your Team',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/pyt/invoices',
            name: 'Pay your team Инвойсы',
            meta: {
              label: 'Инвойсы',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
            component: () => import('@/views/pyt/PytInvoices.vue'),
          },
          {
            path: '/pyt/transactions',
            name: 'Транзакции',
            component: () => import('@/views/pyt/PytTransactions.vue'),
            meta: {
              label: 'Транзакции',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
          },
          {
            path: '/pyt/operations',
            name: 'Операции',
            component: () => import('@/views/pyt/PytOperations.vue'),
            meta: {
              label: 'Операции',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
          },
          {
            path: '/pyt/accounts',
            name: 'Аккаунты',
            component: () => import('@/views/pyt/PytAccounts.vue'),
            meta: {
              label: 'Аккаунты',
              permissions: [PermissionsEnum.PERM_RUBLE_TX],
              auth: true,
            },
          },
          {
            path: '/pyt/partners',
            name: 'Партнеры',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              }
            },
            children: [
              {
                path: '/pyt/partners',
                component: () => import('@/views/pyt/partners/PytPartners.vue'),
                meta: {
                  label: 'Партнеры',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
              },
              {
                path: '/pyt/partners/ru',
                component: () => import('@/views/pyt/partners/PytPartnersRu.vue'),
                name: 'Партнеры РФ',
                meta: {
                  label: 'Партнеры РФ',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
              },
              {
                path: '/pyt/partners/ru/new',
                component: () => import('@/views/pyt/partners/PytNewPartnerRu.vue'),
                name: 'Новый Партнер РФ',
                meta: {
                  label: 'Новый Партнер РФ',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
              },
              {
                path: '/pyt/partners/foreign',
                component: () => import('@/views/pyt/partners/PytPartnersForeign.vue'),
                name: 'Иностранные Партнеры',
                meta: {
                  label: 'Иностранные Партнеры',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
              },
              {
                path: '/pyt/partners/foreign/new',
                component: () => import('@/views/pyt/partners/PytNewPartnerForeign.vue'),
                name: 'Новый Иностранный Партнер',
                meta: {
                  label: 'Новый Иностранный Партнер',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
              },
              {
                path: '/pyt/partners/:id',
                component: () => import('@/views/pyt/partners/PytPartnerPage.vue'),
                name: 'Партнер',
                meta: {
                  label: 'Партнер',
                  permissions: [PermissionsEnum.PERM_RUBLE_TX],
                  auth: true,
                },
              },
            ]
          },
        ]
      },
      {
        path: '/notifications',
        name: 'Уведомления',
        component: () => import('@/views/notifications/Notifications.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/service-documents',
        name: 'Сервисные документы',
        component: () => import('@/views/service-documents/ServiceDocuments.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/ui-notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/ui-notifications/alerts',
        children: [
          {
            path: '/ui-notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/ui-notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/ui-notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
      {
        path: '/documentTemplates',
        meta: {
          label: 'Шаблоны документов',
          permissions: [PermissionsEnum.PERM_DOCUMENT_TEMPLATE],
          auth: true,
        },
        name: 'Шаблоны документов',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            meta: {
              permissions: [PermissionsEnum.PERM_DOCUMENT_TEMPLATE],
              auth: true,
            },
            path: '',
            component: () => import('@/views/templates/Templates.vue'),
          },
          {
            path: ':id',
            meta: {
              label: 'Шаблон',
              permissions: [PermissionsEnum.PERM_DOCUMENT_TEMPLATE],
              auth: true,
            },
            name: 'Шаблон',
            component: () => import('@/views/templates/Template.vue'),
          },
          {
            path: '/new',
            meta: {
              label: 'Добавить шаблон',
              permissions: [PermissionsEnum.PERM_DOCUMENT_TEMPLATE],
              auth: true,
            },
            component: () => import('@/views/templates/Template.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '403',
        name: 'Page403',
        component: () => import('@/views/pages/Page403.vue'),
      },
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        meta: {
          noAuth: true,
        },
        component: () => import('@/views/pages/Login.vue'),
      },
      {
        path: 'register',
        meta: { label: 'Регистрация', noAuth: true },
        name: 'Register',
        component: () => import('@/views/pages/Register.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth || to.meta.permissions) {
    store.dispatch('UPDATE_ADMIN_INFO').then(() => {
      if (!store.getters.IS_AUTHENTICATED) {
        if (!to.query.redirected) {
          const redirectTo = btoa(
            decodeURIComponent(
              encodeURIComponent(
                JSON.stringify({
                  path: to.path,
                  query: to.query,
                }),
              ),
            ),
          )
          next({ name: 'Login', query: { redirectTo: redirectTo } })
        } else {
          next({ name: 'Login' })
        }
      } else {
        if (to.meta.noAuth) {
          next({ name: 'Home' })
        } else if (!to.meta.permissions) {
          next()
        } else {
          const admin = store.getters.ADMIN_INFO as AdminDto
          if (admin && admin.role && admin.role.permissions) {
            const permValues = admin.role.permissions.map((it) => it.value)
            if (
              (to.meta.permissions as string[]).reduce(
                (acc, value) => acc || permValues.includes(value),
                false,
              )
            ) {
              next()
            } else {
              next({ name: 'Page403' })
            }
          } else {
            next({ name: 'Page403' })
          }
        }
      }
    })
  } else {
    next()
  }
})

export default router
