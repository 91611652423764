import permissions from '@/permissions'
import {
  cilBank,
  cilBell,
  cilBriefcase,
  cilCalculator,
  cilCart,
  cilCash,
  cilChartPie,
  cilChatBubble,
  cilCloud,
  cilContact,
  cilCursor,
  cilDescription,
  cilDrop,
  cilEnvelopeClosed,
  cilFile,
  cilHistory,
  cilLoopCircular,
  cilNotes,
  cilPenAlt,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilRuble,
  cilRunning,
  cilSettings,
  cilSitemap,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSwapHorizontal,
  cilTransfer,
  cilUser,
  cilWallet,
} from '@coreui/icons'

export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: cilSpeedometer,
  },
  {
    component: 'CNavItem',
    name: 'Системные Настройки',
    to: '/appSettings',
    icon: cilSettings,
    Permissions: [permissions.PERM_PROPERTIES],
  },
  {
    component: 'CNavTitle',
    name: 'Components',
  },
  {
    component: 'CNavGroup',
    name: 'Creative Objects',
    to: '/products',
    icon: cilCloud,
    Permissions: [permissions.PERM_CO_PRODUCTS],
    items: [
      {
        name: 'Продукты',
        component: 'CNavItem',
        to: '/products',
        icon: cilCart,
        Permissions: [permissions.PERM_CO_PRODUCTS],
      },
      {
        name: 'Сделки',
        component: 'CNavItem',
        to: '/co-deals',
        icon: cilSwapHorizontal,
        Permissions: [permissions.PERM_CO_PRODUCT_DEALS],
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Pay Your Team',
    to: '/pyt',
    icon: cilCloud,
    Permissions: [permissions.PERM_CO_PRODUCTS],
    items: [
      {
        name: 'Инвойсы',
        component: 'CNavItem',
        to: '/pyt/invoices',
        icon: cilSpreadsheet,
        Permissions: [permissions.PERM_CO_PRODUCTS],
      },
      {
        name: 'Транзакции',
        component: 'CNavItem',
        to: '/pyt/transactions',
        icon: cilTransfer,
        Permissions: [permissions.PERM_CO_PRODUCTS],
      },
      {
        name: 'Операции',
        component: 'CNavItem',
        to: '/pyt/operations',
        icon: cilRuble,
        Permissions: [permissions.PERM_CO_PRODUCTS],
      },
      {
        name: 'Аккаунты',
        component: 'CNavItem',
        to: '/pyt/accounts',
        icon: cilUser,
        Permissions: [permissions.PERM_CO_PRODUCTS],
      },
      {
        name: 'Партнеры',
        component: 'CNavItem',
        to: '/pyt/partners',
        icon: cilContact,
        Permissions: [permissions.PERM_CO_PRODUCTS],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Журнал действий',
    to: '/journal',
    icon: cilPenAlt,
    Permissions: [permissions.PERM_USERS_READ],
  },
  {
    component: 'CNavItem',
    name: 'Пользователи',
    to: '/users',
    icon: cilPeople,
    Permissions: [permissions.PERM_USERS_READ],
  },
  {
    component: 'CNavGroup',
    name: 'Администраторы',
    route: '/admins',
    icon: cilPeople,
    Permissions: [permissions.PERM_ADMINS],
    items: [
      {
        name: 'Список администраторов',
        component: 'CNavItem',
        to: '/admins',
        icon: cilPeople,
        Permissions: [permissions.PERM_ADMINS],
      },
      {
        name: 'Роли',
        component: 'CNavItem',
        to: '/roles',
        icon: cilPeople,
        Permissions: [permissions.PERM_ADMINS],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Сервисные документы',
    to: '/service-documents',
    icon: cilNotes,
    Permissions: [permissions.PERM_SERVICE_DOCUMENTS],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Арбитраж',
  //   to: '/arbitrage',
  //   icon: cilBalanceScale,
  //   Permissions: [permissions.PERM_ARBITRAGE],
  // },
  {
    component: 'CNavGroup',
    name: 'Библиотека файлов',
    to: '/files',
    icon: cilFile,
    Permissions: [permissions.PERM_FILES],
    items: [
      {
        name: 'Библиотека файлов',
        component: 'CNavItem',
        to: '/files',
        icon: cilFile,
        Permissions: [permissions.PERM_FILES],
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Финансы',
    route: '/finance',
    icon: cilCash,
    Permissions: [permissions.PERM_RUBLE_TX],
    items: [
      {
        name: 'Управление сделками',
        component: 'CNavItem',
        to: '/finance/legal-transactions',
        icon: cilBriefcase,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        name: 'Массовые выплаты',
        component: 'CNavItem',
        to: '/finance/mass-payments',
        icon: cilSitemap,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        to: '/finance/invoice',
        name: 'Инвойсы',
        icon: cilRuble,
        Permissions: [permissions.PERM_RUBLE_TX],
        component: 'CNavItem',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Точка банк',
    route: '/tochka',
    icon: cilBank,
    items: [
      {
        name: 'Проведение оплаты',
        component: 'CNavItem',
        to: '/tochka/transfer',
        icon: cilCash,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        name: 'Пополнение баланса CO',
        component: 'CNavItem',
        to: '/tochka/top-up',
        icon: cilCash,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        name: 'Сделки',
        component: 'CNavItem',
        to: '/tochka/deals',
        icon: cilRuble,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        name: 'Платежи',
        component: 'CNavItem',
        to: '/tochka/payments',
        icon: cilWallet,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        name: 'Неидентифицированные платежи',
        component: 'CNavItem',
        to: '/tochka/unidentified-payments',
        icon: cilWallet,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        name: 'Бенефициары',
        component: 'CNavItem',
        to: '/tochka/beneficiary-list',
        icon: cilPeople,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        name: 'Виртуальные транзакции',
        component: 'CNavItem',
        to: '/tochka/transactions',
        icon: cilLoopCircular,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
      {
        name: 'Виртуальные аккаунты',
        component: 'CNavItem',
        to: '/tochka/virtual-accounts',
        icon: cilPeople,
        Permissions: [permissions.PERM_RUBLE_TX],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Шаблоны документов',
    to: '/documentTemplates',
    icon: cilNotes,
    Permissions: [permissions.PERM_DOCUMENT_TEMPLATE],
  },
  {
    component: 'CNavItem',
    name: 'Аудит',
    to: '/audit',
    icon: cilHistory,
    Permissions: [permissions.PERM_AUDIT],
  },
  {
    component: 'CNavGroup',
    name: 'Навыки',
    to: '/skills',
    icon: cilDescription,
    Permissions: [permissions.PERM_SKILLS],
    items: [
      {
        name: 'Навыки',
        component: 'CNavItem',
        to: '/skills',
        icon: cilDescription,
        Permissions: [permissions.PERM_SKILLS],
      },
    ],
  },
  {
    name: 'Рассылка sendpulse',
    component: 'CNavItem',
    to: '/emails',
    icon: cilEnvelopeClosed,
    Permissions: [permissions.PERM_EMAIL],
  },
  {
    name: 'Чат поддержки',
    component: 'CNavItem',
    to: '/support-chat',
    icon: cilChatBubble,
    Permissions: [permissions.PERM_SUPPORT],
  },
  {
    name: 'Обратная связь',
    component: 'CNavItem',
    to: '/feedbacks',
    icon: cilRunning,
    Permissions: [permissions.PERM_FEEDBACKS],
  },
  {
    component: 'CNavGroup',
    name: 'Core UI components',
    icon: cilPuzzle,
    items: [
      {
        component: 'CNavGroup',
        name: 'Base',
        to: '/base',
        icon: cilPuzzle,
        items: [
          {
            component: 'CNavItem',
            name: 'Accordion',
            to: '/base/accordion',
          },
          {
            component: 'CNavItem',
            name: 'Breadcrumbs',
            to: '/base/breadcrumbs',
          },
          {
            component: 'CNavItem',
            name: 'Cards',
            to: '/base/cards',
          },
          {
            component: 'CNavItem',
            name: 'Carousels',
            to: '/base/carousels',
          },
          {
            component: 'CNavItem',
            name: 'Collapses',
            to: '/base/collapses',
          },
          {
            component: 'CNavItem',
            name: 'List Groups',
            to: '/base/list-groups',
          },
          {
            component: 'CNavItem',
            name: 'Navs & Tabs',
            to: '/base/navs',
          },
          {
            component: 'CNavItem',
            name: 'Paginations',
            to: '/base/paginations',
          },
          {
            component: 'CNavItem',
            name: 'Placeholders',
            to: '/base/placeholders',
          },
          {
            component: 'CNavItem',
            name: 'Popovers',
            to: '/base/popovers',
          },
          {
            component: 'CNavItem',
            name: 'Progress',
            to: '/base/progress',
          },
          {
            component: 'CNavItem',
            name: 'Spinners',
            to: '/base/spinners',
          },
          {
            component: 'CNavItem',
            name: 'Tables',
            to: '/base/tables',
          },
          {
            component: 'CNavItem',
            name: 'Tooltips',
            to: '/base/tooltips',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Buttons',
        to: '/buttons',
        icon: cilCursor,
        items: [
          {
            component: 'CNavItem',
            name: 'Buttons',
            to: '/buttons/standard-buttons',
          },
          {
            component: 'CNavItem',
            name: 'Button Groups',
            to: '/buttons/button-groups',
          },
          {
            component: 'CNavItem',
            name: 'Dropdowns',
            to: '/buttons/dropdowns',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Forms',
        to: '/forms',
        icon: cilNotes,
        items: [
          {
            component: 'CNavItem',
            name: 'Form Control',
            to: '/forms/form-control',
          },
          {
            component: 'CNavItem',
            name: 'Select',
            to: '/forms/select',
          },
          {
            component: 'CNavItem',
            name: 'Checks & Radios',
            to: '/forms/checks-radios',
          },
          {
            component: 'CNavItem',
            name: 'Range',
            to: '/forms/range',
          },
          {
            component: 'CNavItem',
            name: 'Input Group',
            to: '/forms/input-group',
          },
          {
            component: 'CNavItem',
            name: 'Floating Labels',
            to: '/forms/floating-labels',
          },
          {
            component: 'CNavItem',
            name: 'Layout',
            to: '/forms/layout',
          },
          {
            component: 'CNavItem',
            name: 'Validation',
            to: '/forms/validation',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Charts',
        to: '/charts',
        icon: cilChartPie,
      },
      {
        component: 'CNavGroup',
        name: 'Icons',
        to: '/icons',
        icon: cilStar,
        items: [
          {
            component: 'CNavItem',
            name: 'CoreUI Icons',
            to: '/icons/coreui-icons',
            badge: {
              color: 'info',
              text: 'NEW',
            },
          },
          {
            component: 'CNavItem',
            name: 'Brands',
            to: '/icons/brands',
          },
          {
            component: 'CNavItem',
            name: 'Flags',
            to: '/icons/flags',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Notifications',
        to: '/ui-notifications',
        icon: cilBell,
        items: [
          {
            component: 'CNavItem',
            name: 'Alerts',
            to: '/ui-notifications/alerts',
          },
          {
            component: 'CNavItem',
            name: 'Badges',
            to: '/ui-notifications/badges',
          },
          {
            component: 'CNavItem',
            name: 'Modals',
            to: '/ui-notifications/modals',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Widgets',
        to: '/widgets',
        icon: cilCalculator,
        badge: {
          color: 'primary',
          text: 'NEW',
          shape: 'pill',
        },
      },
      {
        component: 'CNavTitle',
        name: 'Theme',
      },
      {
        component: 'CNavItem',
        name: 'Colors',
        to: '/theme/colors',
        icon: cilDrop,
      },
      {
        component: 'CNavItem',
        name: 'Typography',
        to: '/theme/typography',
        icon: cilPencil,
      },
    ],
  },
  // {
  //   component: 'CNavTitle',
  //   name: 'Extras',
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Pages',
  //   to: '/pages',
  //   icon: cilStar,
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Login',
  //       to: '/pages/login',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Register',
  //       to: '/pages/register',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 404',
  //       to: '/pages/404',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 500',
  //       to: '/pages/500',
  //     },
  //   ],
  // },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
