import axios, {
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosResponse,
  ParamsSerializerOptions,
  RawAxiosRequestHeaders,
} from 'axios'

import qs from 'qs'
import debug from '@/debug'

class RestTemplate {
  private static HEADERS = {
    'X-CSRF-TOKEN':
      document.head.querySelector('meta[name="_csrf"]')?.getAttribute('content'),
    Accept: 'application/json, text/javascript, */*; q=0.01',
    'X-Requested-With': 'XMLHttpRequest',
  }

  private static PARAMS_SERIALIZER: ParamsSerializerOptions = {
    encode: (params) =>
      typeof params !== 'object'
        ? params
        : qs.stringify(params, { arrayFormat: 'comma', encode: false }),
  }

  private static CONFIG: AxiosRequestConfig = {
    headers: this.HEADERS as RawAxiosRequestHeaders,
    paramsSerializer: this.PARAMS_SERIALIZER,
    baseURL: process.env['VUE_APP_ROOT_API'],
    withCredentials: false,
  }

  private static MULTIPART_CONFIG: AxiosRequestConfig = {
    headers: {
      ...this.CONFIG.headers,
      'Content-Type': 'multipart/form-data',
    } as RawAxiosRequestHeaders,
    baseURL: process.env['VUE_APP_ROOT_API'],
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      console.log(progressEvent.loaded),
    withCredentials: false,
  }

  constructor() {
    if (debug.debug) {
      RestTemplate.CONFIG.withCredentials = true
      RestTemplate.MULTIPART_CONFIG.withCredentials = true
    }
  }

  public get<T>(url: string, params?: object): Promise<AxiosResponse<T>> {
    return axios.get(url, {
      ...RestTemplate.CONFIG,
      params: params,
    } as AxiosRequestConfig)
  }

  public getFile(
    url: string,
    params?: object,
    type?: string | null,
    label?: string,
  ): Promise<void> {
    return axios
      .get(url, {
        ...RestTemplate.MULTIPART_CONFIG,
        responseType: 'blob',
        params,
      })
      .then((response) => {
        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], { type: type || contentType })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        if (typeof label === 'string') {
          link.download = label
        }
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(console.error)
  }

  public post<T>(url: string, data?: object): Promise<AxiosResponse<T>> {
    return axios.post(url, data, RestTemplate.CONFIG)
  }

  public put<T>(url: string, data?: object): Promise<AxiosResponse<T>> {
    return axios.put(url, data, RestTemplate.CONFIG)
  }

  public delete<T>(url: string, params?: object): Promise<AxiosResponse<T>> {
    return axios.delete(url, {
      ...RestTemplate.CONFIG,
      params: params,
    } as AxiosRequestConfig)
  }

  public postWithFile<T>(
    url: string,
    data?: object,
  ): Promise<AxiosResponse<T>> {
    return axios.post(url, data, RestTemplate.MULTIPART_CONFIG)
  }

  public getPaymentOrderFile<T>(url, data): Promise<any> {
    return axios
      .post(url, data, {
        ...RestTemplate.CONFIG,
        responseType: 'arraybuffer',
      })
      .then((res) => RestTemplate.mapBufferToBlob(res, data.payment_id))
      .catch(console.error)
  }

  public postForFile<T>(url: string, data?: any): Promise<any> {
    return axios
      .post(url, data, {
        ...RestTemplate.CONFIG,
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse<T>) => {
        const bytes = new Uint8Array(response.data as Iterable<number>)
        const contentType = response.headers['content-type']
        const name = 'result.pdf'
        return new File([bytes], name, {
          type: contentType ? contentType : 'octet/stream',
          lastModified: Number.parseInt(response.headers['last-modified'] || '0')
        })
      })
      .catch(console.error)
  }

  public static mapBufferToBlob(response, payment_id) {
    const bytes = new Uint8Array(response.data)
    const contentType = response.headers.get('content-type')
    const name = `PLATEZHNOE_PORUCHENIE_${payment_id}`
    return new File([bytes], name, {
      type: contentType ? contentType : 'octet/stream',
      lastModified: response.headers.get('last-modified')
    })
  }
}

export default new RestTemplate()
export const baseUrl = process.env['VUE_APP_ROOT_API']

export const csrf = document.head.querySelector('meta[name="_csrf"]')?.getAttribute('content');
