<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem v-if="hasPermission(PermissionsEnum.PERM_USERS_READ)">
          <CNavLink href="/users">Список пользователей</CNavLink>
        </CNavItem>
        <CNavItem v-if="hasPermission(PermissionsEnum.PERM_PROPERTIES)">
          <CNavLink href="/appSettings">Системные настройки</CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownBell class="mx-2"/>
        <CNavItem>
          <CNavLink href="/support-chat">
            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import AppHeaderDropdownBell from "@/components/AppHeaderDropdownBell";
import permissionHelper from '@/permissionHelper';
import { PermissionsEnum } from '@/permissions.enum';
import { useStore } from '@/store';

export default {
  name: 'AppHeader',
  computed: {
    PermissionsEnum() {
      return PermissionsEnum
    }
  },
  components: {
    AppHeaderDropdownBell,
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    const store = useStore();
    const { hasPermission } = permissionHelper();
    return {
      logo,
      store,
      hasPermission
    }
  },
}
</script>
