<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CIcon icon="cil-bell" size="lg" class="my-2" />
      <CBadge
        shape="rounded-pill"
        color="info-gradient"
        class="position-absolute top-0 end-0"
        size="sm"
        v-if="unreadCount > 0"
      >
        {{ unreadCount }}
      </CBadge>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0 dropdown-menu">
      <CDropdownHeader class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10">
        <strong>У Вас {{ unreadCount }} непрочитанных сообщений</strong>
      </CDropdownHeader>
      <div class="notifications-wrapper" v-if="notifications.length">
        <div class="notification" v-for="item of notifications" :key="item" @click="setViewed(item)">
          <div class="message position-relative">
            <div class="justify-content-end d-flex">
              <small class="date mt-1">{{ formatDate(item.created) }}</small>
            </div>
            <div class="small text-medium-emphasis text-truncate">
              {{item.text}}
            </div>
            <CBadge
              v-if="!item.viewed"
              class="position-absolute top-0 p-1 badge-new-alert"
              color="primary-gradient"
              shape="rounded-pill"
            >
              <span class="visually-hidden">New alerts</span>
            </CBadge>
          </div>
        </div>
      </div>
      <template v-if="!notifications.length">
        <div>
          Нет уведомлений
        </div>
      </template>
      <CDropdownDivider />
      <CDropdownItem href="/notifications">
        Посмотреть все уведомления
        <CIcon icon="cil-external-link" class="text-success" />
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script lang="ts">
import { CBadge, CDropdown, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle, } from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { useStore } from '@/store'
import { computed, onMounted, ref } from 'vue'
import http from '@/restTemplate'
import { AdminNotificationDto, DateAsString, PageDto } from '@/models/admin-backend';
import momentTz from 'moment-timezone';

export default {
  name: 'AppHeaderDropdownBell',
  components: {
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownHeader,
    CDropdownItem,
    CIcon,
    CBadge,
  },
  setup() {
    const store = useStore()
    const notifications = ref<Array<AdminNotificationDto>>([])

    onMounted(() => {
      getNotifications()
    })

    const getNotifications = () => {
      const params = {
        pageSize: 20,
        pageNumber: 0,
        direction: 'DESC',
        column: 'created',
      }
      http.get<PageDto<AdminNotificationDto>>(`/api/adminNotification`, params).then((response) => {
        notifications.value = response.data.content!;
      })
    }

    const setViewed = (item) => {
      http.post('/api/adminNotification/setViewed', { notificationId: item.id, viewed: true }).then((res) => {
        item.viewed = true
      })
    }

    const formatDate = (date: DateAsString, format = 'DD MMM YYYY HH:mm:ss'): string => {
      return momentTz(date, undefined, 'ru', true).tz('Europe/Moscow').format(format)
    }

    return {
      admin: computed(() => store.state.adminInfo),
      notifications,
      unreadCount: computed(() => store.state.unreadNotifications),
      setViewed,
      formatDate,
    }
  },
}
</script>

<style scoped lang="scss">
.dropdown-menu {
  width: 500px;
}

.badge-new-alert {
  right: -8px;
}

.notifications-wrapper {
  max-height: 500px;
  overflow: auto;

  .notification {
    padding: 12px;
    cursor: pointer;

    &:hover {
      background: var(--cui-dropdown-link-hover-bg);
    }
  }

  .date {
    font-size: 10px;
  }
}
</style>
