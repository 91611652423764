import {
  cibBehance,
  cibCcAmex,
  cibCcMastercard,
  cibCcVisa,
  cibDribbble,
  cibFacebook,
  cibFlickr,
  cibGithub,
  cibGooglePay,
  cibInstagram,
  cibLinkedin,
  cibPaypal,
  cibPinterest,
  cibReddit,
  cibStripe,
  cibTumblr,
  cibTwitter,
  cibVimeo,
  cibVk,
  cibXing,
  cibYahoo,
  cibYoutube,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilArrowLeft,
  cilArrowRight,
  cilArrowThickToBottom,
  cilArrowTop,
  cilBalanceScale,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCash,
  cilChartPie,
  cilCheck,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronTop,
  cilCloudDownload,
  cilCommentSquare,
  cilCursor,
  cilDelete,
  cilDescription,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilFile,
  cilGlobeAlt,
  cilGrid,
  cilHistory,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLibraryAdd,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMinus,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPlus,
  cilPuzzle,
  cilRuble,
  cilRunning,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilSync,
  cilTask,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilZoomIn,
  cilChatBubble,
  cilPenAlt,
  cilBank,
  cilWallet,
  cilLoopCircular,
  cilExternalLink,
  cilMenu,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilArrowRight,
    cilArrowLeft,
    cilArrowTop,
    cilArrowThickToBottom,
    cilBan,
    cilBasket,
    cilBalanceScale,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCash,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDescription,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilHistory,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLibraryAdd,
    cilDelete,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilMinus,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilPlus,
    cilRuble,
    cilRunning,
    cilSettings,
    cilShieldAlt,
    cilSync,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilTrash,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilZoomIn,
    cilChatBubble,
    cilPenAlt,
    cilBank,
    cilWallet,
    cilLoopCircular,
    cilExternalLink,
    cilMenu,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
  }
)
